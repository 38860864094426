@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.GiftCardIframe {
  padding: 0 var(--page-gutter);
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe {
  display: block;
  width: 100%;
  border: none;
  height: px(2000);
  overflow: hidden;

  @media (max-width: 986px) {
    height: px(2800);
  }
}

.hidden {
  display: none;
}
