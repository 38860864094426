@import "@/styles/shared";
/* stylelint-disable-next-line no-empty-source */
.LocationHero {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  max-width: var(--section-max-width);
  margin: 0 auto;
}

/* ===============
Text Side
=============== */

.textSide {
  grid-column: 1/-1;
  order: 2;
  margin-top: px(27);

  @include bp(tablet) {
    margin-top: 0;
    grid-column: 1/7;
    order: 1;
  }
}

.breadcrumbs {
  margin-bottom: px(27);

  @include bp(tablet) {
    margin-bottom: px(40);
  }
}

.comingSoonText,
.eyebrowText {
  @include font-detail;
  color: var(--red);
  margin-bottom: px(16);
}

.title {
  @include font-h4;
}

.heroDescription {
  margin-top: px(24);
}

.areaTitle {
  @include font-sh5;
}

.areaTitle__at {
  text-transform: lowercase;
  font-style: italic;
}

.emailSignupContainer {
  margin: px(32) 0;
}

.emailSignupContainer__text {
  @include font-body-l;
  margin-bottom: px(48);

  strong {
    font-weight: 500;
  }
}

.buttons {
  margin-top: px(40);
  display: flex;
  flex-direction: column;
  gap: px(8);
  position: relative;
  z-index: 5;
  max-width: fit-content;

  @include bp(tablet) {
    margin-top: px(48);
  }
}

.topButtons {
  display: block;
  width: 100%;
}

.reserveButton {
  width: 100%;

  button {
    width: 100% !important;
  }
}

.bottomButtons {
  display: flex;
  gap: px(8);
}

.contactMethodsContainer {
  margin-top: px(56);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: px(16);

  @include bp(tablet) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: px(72);
    gap: 0;
  }
}

.phoneNumber {
  @include font-body;
}

.address {
  @include font-body;
}

.reservationOnly {
  padding-top: px(18);
  display: flex;
  align-items: center;
  gap: px(8);

  span {
    @include font-detail-s;
    margin-top: px(4);
  }
}

/* ===============
Image Side
=============== */

.imageSide {
  grid-column: 1/-1;
  order: 1;

  @include bp(tablet) {
    grid-column: 7/-1;
    order: 2;
  }
}
